<template>
  <div>
    <el-card shadow="never">
      <el-row>
        <el-col :span="12">
          <span>{{ $tc('feature', 2) }}</span>
        </el-col>
        <el-col :span="12">
          <el-button
            v-cus-loading="form.loading"
            size="mini"
            type="primary"
            style="float: right"
            @click="onSubmit"
          >
            {{ $tc('save') }}
          </el-button>
          <el-button
            v-cus-loading="form.loading"
            size="mini"
            type="text"
            style="float: right"
            @click="$router.push({ name: 'FeatureIndex' })"
          >
            {{ $tc('cancel') }}
          </el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="box-card">
      <el-form
        ref="form"
        v-loading="form.loading"
        :model="form.model"
        :rules="form.rules"
        label-position="top"
      >
        <el-row :gutter="10">
          <el-col :sm="12" :xs="24">
            <el-form-item :label="$tc('service', 1)" prop="serviceId">
              <select-service v-model="form.model.serviceId"></select-service>
            </el-form-item>
            <el-form-item :label="$tc('name', 1)" prop="featureName">
              <el-input
                v-model="form.model.featureName"
                :maxlength="64"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$tc('type', 1)" prop="featureType">
              <el-select
                v-model="form.model.featureType"
                @change="onChangeFeatureType"
              >
                <el-option
                  value="closedLimit"
                  label="Limite cerrado"
                ></el-option>
                <el-option value="openLimit" label="Limite abierto"></el-option>
                <el-option value="access" label="Si ó No"></el-option>
                <el-option value="info" label="Info"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="
                form.model.featureType !== 'info' &&
                form.model.featureType !== 'openLimit'
              "
              :label="$tc('price', 1)"
              prop="featureUnitPriceCustom"
            >
              <el-input
                v-model.number="form.model.featureUnitPriceCustom"
                type="number"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :xs="24">
            <el-form-item :label="$t('icon')">
              <el-input
                v-model="form.model.featureIcon"
                :maxlength="80"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$tc('description', 1)"
              prop="featureDescription"
            >
              <el-input
                v-model="form.model.featureDescription"
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="form.model.featureType.toLowerCase().includes('limit')"
              label="Es controlado (Plan/Paquete)"
            >
              <el-switch v-model="form.model.featureIsControlled"></el-switch>
              <span class="help"
                >Si configura esta opcion, esta caracteristica sera controlada
                por el plan o paquete asignado en la empresa.</span
              >
            </el-form-item>
            <el-form-item :label="$t('isVisible')">
              <el-switch v-model="form.model.featureIsVisible"></el-switch>
            </el-form-item>
            <el-form-item :label="$t('enabled')">
              <el-switch v-model="form.model.featureEnabled"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import form from '@/views/common/form'
import update from '@/views/common/update'
import model from './model'
import rules from './rules'

export default {
  name: 'FeatureUpdate',
  extends: update('features', { model }),
  data() {
    return {
      form: form({ model, rules }),
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    onChangeFeatureType(featureType) {
      const _this = this
      _this.$emit('input', featureType)
      _this.form.model.featurePackageQuantity = 0
      _this.form.model.featurePackagePrice = 0
      _this.form.model.featureIsControlled = false
    },
  },
}
</script>
