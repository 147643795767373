import { fieldRequiredByBlurAndChange } from '@/helpers/validations'

export default {
  serviceId: [fieldRequiredByBlurAndChange],
  featureName: [fieldRequiredByBlurAndChange],
  featureType: [fieldRequiredByBlurAndChange],
  featureAddonQuantity: [fieldRequiredByBlurAndChange],
  featureUnitPriceCustom: [fieldRequiredByBlurAndChange],
  featurePrice: [fieldRequiredByBlurAndChange],
}
