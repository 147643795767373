export default () => ({
  id: 0,
  serviceId: '',
  featureName: '',
  featureType: '',
  featureUnitPriceCustom: '',
  featureIcon: '',
  featureIsControlled: false,
  featureIsVisible: false,
  featureDescription: '',
  featureOrder: 0,
  featureEnabled: true,
})
